<template>
  <div>
    <!-- <v-row v-if="isSuperAdmin">
      <v-col class="d-flex align-center">
        <v-text-field outlined v-model="search_name" label="搜尋項目名稱" hide-details="auto"></v-text-field>
        <v-btn color="primary" class="ml-2" @click="searchEvent">搜尋</v-btn>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <router-link :to="{ name: 'add-product' }">
          <v-btn color="primary">Add Product</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :loading="load_table" :headers="product_header" :items="product_list" class="table-rounded" disable-sort>
          <template v-slot:[`item.cover`]="{ item }">
            <div>
              <v-img :src="item.cover" width="80" height="80" class="my-1"></v-img>
            </div>
          </template>
          <template v-slot:[`item.view_button`]="{ item }">
            <span>
              <router-link :to="{ name: item.route_link, params: { id: item.id } }" style="text-decoration: none">
                <v-btn text color="primary"> Detail </v-btn>
              </router-link>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import AlertBox from '@/components/AlertBox'
import { globalFunc } from '@/global'
import { mapActions } from 'vuex'
import { getData } from '@/api'

export default {
  name: 'event-management',
  components: {
    DataTableWithPagination,
    AlertBox,
  },
  data: () => ({
    user_center_id: -1,
    verifiedError: false,
    
    product_header: [
      { text: 'Product Cover', value: 'cover' },
      { text: 'Product Name', value: 'name' },
      { text: 'Price', value: 'price' },
      { text: 'Status', value: 'active' },
      { text: '', value: 'view_button' },
    ],
    product_list: [],
    load_table: true,
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    total_items: 0,
    current_page: 0,
    current_limit: 10,
    options: {},
    search_name:'',
  }),
  methods: {
    ...mapActions(['reset']),
    /**
     * Initializing the product data
    */
    async initializeProductData() {
      this.load_table = true
      try {
        let params = new URLSearchParams()
        
        params.set('get_all_product',null)
        
        let result = await getData(params) 
        console.log('--- Get product data ---')
        console.log(result)
        
        if(result) {
          let temp = []

          for (let i = 0; i < result.length; i++) {
            let active = (result[i].active==true)?"Active":"Inactive";
            let splash = "";
            if(result[i].image) {
              splash = result[i].image
            } else {
              splash = require('@/assets/images/images/no_image.png')
            }
            temp.push({
              id: result[i].id,
              cover: splash,
              name: result[i].name,
              price: result[i].price,
              active: active,
              route_link: 'product-management-detail',
            })
          }
          this.product_list = temp
        }
        this.load_table = false

      } catch (error) {
        console.log('--- Get event data fail ---')
        this.load_table = false
        this.event_list = []
        console.log(error)
      }
    },
    /**
     * Searching the event
    */
    searchEvent() {
      this.initializeEventData(this.search_name)
    },
    /**
     * Kicking out the user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      setTimeout(() => {
        this.alertBox.show = false
        let send_data = []
        send_data['method'] = 'resetData'
        this.reset(send_data)
        this.$router.push({ name: 'Login' })
      }, 3000)
    },
  },
  /**
   * Initializing at the beginning
  */
  created() {
    setTimeout(async () => {
      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {
        try {
          this.initializeProductData()
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.load_table = false
          this.verifiedError = true

          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          setTimeout(() => {
            this.alertBox.show = false
          }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
}
</script>
